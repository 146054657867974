.v-quick {
    .card-body {
        padding: 0.5rem;
    }

    &__container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 580px) {
            flex-direction: column;
        }
    }

    &__activity {
        // border: 1px dotted red;
        display: flex;
        flex: 1 0;
        @media (max-width: 580px) {
            flex: 0 0 100%;
            margin-right: 0 !important;
        }

        .form-group {
            width: 100%;
            margin-bottom: 6px;
        }

        .form-control {
            border: none;
        }

        .v-select {
            padding-left: 0px;
            padding-right: 0px;

            .vs__dropdown-toggle {
                border: 1px solid $white;

                .vs__actions {
                    opacity: 0;
                }

                &:hover {
                    border: 1px solid rgba(60, 60, 60, 0.26);

                    .vs__actions {
                        opacity: 1;
                    }
                }

                &:focus {
                    border: 1px solid rgba(60, 60, 60, 0.26);
                }

                input::placeholder {
                    opacity: 0.7;
                }
            }

            .vs__selected {
                font-size: 15px;
                color: $body-color;
                //word-break: break-all;
            }

            .vs__search,
            .vs__search:focus {
                font-size: 1.1em;
            }

            @media (max-width: 580px) {
                width: 297px;
                margin-bottom: 30px;

                .vs__dropdown-toggle {
                    border: 1px solid rgba(60, 60, 60, 0.26);

                    .vs__actions {
                        opacity: 1;
                    }
                }
            }

            @media (max-width: 320px) {
                width: 240px;
            }
        }

        .vs--open {
            .vs__dropdown-toggle {
                border: 1px solid rgba(60, 60, 60, 0.26);

                .vs__actions {
                    opacity: 1;
                }
            }
        }

        .vs__dropdown-menu {
            margin-top: -4px !important;
        }
    }

    &__billable {
        //border: 1px dotted green;
        display: flex;
        flex: 0 0 140px;
        justify-content: center;

        &--text {
            //color: $gray-900;
            margin-left: 8px;
            font-size: 15px;
        }

        input[type="checkbox"] {
            //width: 15px;
            //filter: grayscale(1);
            width: 17px;
            height: 16px;
            margin: 4px;
            -webkit-appearance: none;
            -moz-appearance: none;
            -o-appearance: none;
            appearance: none;
            //outline: 1px solid rgb(14, 14, 14);
            box-shadow: none;
            font-size: 0.65rem;
            text-align: center;
            line-height: 1rem;
            background: $gray-300;
            border-radius: 3px;
            font-family: "Font Awesome 5 Pro";

            &:checked:after {
                content: "";
                color: white;
                background: $primary;
                padding: 3px 4px;
                border-radius: 3px;
            }
            &:hover {
                box-shadow: 0 0 0 1px $primary;
                transition: 0.2s;
            }
        }

        @media (max-width: 580px) {
            flex: 0 0 100%;
            margin-bottom: 40px;
        }
    }

    &__datepicker {
        //border: 1px dotted blue;
        display: flex;
        flex: 0 0 140px;
        justify-content: center;

        &--date {
            font-size: 15px;
            //color: $primary;
            padding: 5px 15px;

            &:hover {
                border: 1px solid $border-color;
                border-radius: 6px;
                padding: 5px 15px;
            }
            @media (max-width: 580px) {
                border: 1px solid $border-color;
                border-radius: 6px;
            }
        }

        &--date-warn {
        }

        .vc-popover-content-wrapper {
            z-index: 2200;
        }

        @media (max-width: 580px) {
            flex: 0 0 100%;
            margin-bottom: 30px;
            margin-top: 30px;
        }
    }

    &__timepicker {
        //border: 1px dotted red;
        display: flex;
        flex: 0 0 250px;
        justify-content: center;

        @media (max-width: 580px) {
            flex: 0 0 100%;
            margin-bottom: 40px;
        }
    }

    &__save {
        //border: 1px dotted pink;
        display: flex;
        flex: 0 0 60px;
        justify-content: center;

        .cannot-submit {
            color: $gray-200;
        }

        .m-click:hover {
            font-weight: 900;
        }

        @media (max-width: 580px) {
            flex: 0 0 100%;
            margin-bottom: 30px;
            font-size: 24px;
        }
    }

    .text-warning {
        line-height: 3rem;
        font-size: 15px;
    }
}
