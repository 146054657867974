
            @import "@/assets/sass/vars.portal.scss";
          


































































































































































































































































































.daylog-form {
    .label {
        text-align: center;
        margin-top: 10px;
        font-size: 14px;
    }

    .status {
        text-align: center;
        margin-top: 30px;
        font-size: 14px;
        color: $info;
    }
}
