.v-system-task {
    border-radius: 6px;
    border: 1px solid $gray-100;
    background-color: $gray-100;

    &--not-work {

    }

    &--sickness {

    }

    &--holidays {
        
    }

    &__container {
        display: flex;
        align-items: center;
        padding: 15px;
    }

    @media (max-width: 580px) {
        width: 100%;
    }
}