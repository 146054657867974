
            @import "@/assets/sass/vars.portal.scss";
          






















































































































































































































































.v-system-act {
    &__date {
        .form-group {
            margin-bottom: 8px;
        }
    }

    &__time-reg {
        .v-triple-time {
            justify-content: center;
        }
    }
}
