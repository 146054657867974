
            @import "@/assets/sass/vars.portal.scss";
          








































































































































































































































.btn-german {
    background: linear-gradient(
        to bottom,
        #000 0%,
        #000 33.33%,
        #d00 33.33%,
        #d00 66.66%,
        #ffcc00 66.66%,
        #ffcc00 100%
    ) !important;
    border: none; /* Remove border if desired */
    color: #fff !important; /* Adjust text color for readability */
    font-weight: bold !important;
    padding: 0.5rem 1rem;
}
