
            @import "@/assets/sass/vars.portal.scss";
          





























































































































































.v-avatar-user-select-container {
    background: white;
    width: 250px;
    position: absolute;
    z-index: 2004;
    right: 192px;
    top: 48px;

    .v-avatar-user-select-control {
        z-index: 2005;
    }
}

.v-avatar-user-select-container-form {
    background: white;
    width: 250px;
    position: absolute;
    z-index: 2004;
    left: 0px;
    top: 36px;

    .v-avatar-user-select-control-form {
        z-index: 2005;
    }
}
