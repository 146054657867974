
            @import "@/assets/sass/vars.portal.scss";
          
























































.v-loader {
    display: flex; 
    align-items: center; 
    justify-content: center;
    flex-direction: column;

    &__slot_msg {
        margin-top: 15px;
        font-size: 18px;            
    }
}
