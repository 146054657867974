
            @import "@/assets/sass/vars.portal.scss";
          















































.bread-bag {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $gray-label;
    margin-left: -30px !important;
    margin-right: -30px !important;
    border-top: 1px solid $gray-label;

    @media (max-width: 767px) {
        margin-left: -15px !important;
        margin-right: -15px !important;
    }

    .bread-bag-left {
        padding-left: 30px;
        //border: 1px dashed red;
        display: flex;
        flex-grow: 2;

        @media (max-width: 767px) {
            padding-left: 15px;
            padding-right: 15px;
            flex: 0 0 100%;
        }

        .bag-breadcrumbs {
            margin-top: 5px;
        }
    }

    .bread-bag-right {
        //border: 1px dashed green;
        justify-content: flex-end;
        display: flex;
        flex-grow: 1;
        padding-right: 30px;
        text-align: right;

        @media (max-width: 767px) {
            padding-right: 15px;
            flex: 0 0 100%;
            padding-left: 15px;
            overflow-x: auto !important;

            .row {
                overflow-x: auto !important;
                overflow-y: visible !important;
                flex-wrap: nowrap;
                height: 43px;
                -ms-overflow-style: none; /* IE and Edge */
                scrollbar-width: none; /* Firefox */

                &::-webkit-scrollbar {
                    display: none;
                }

                .navi-block {
                    padding-top: 10px;
                }

                .navi-block__shadow {
                    height: 3px;
                    top: 40px;
                }
            }
        }
    }
}
