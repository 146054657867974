
            @import "@/assets/sass/vars.portal.scss";
          


































































.formulate-datepicker {
    .mx-datepicker {
        width: 100% !important;
    }
}
