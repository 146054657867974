
            @import "@/assets/sass/vars.portal.scss";
          







































































































.v-goto-customer {
    margin-top: 0;

    .vs__dropdown-menu {
        width: auto;
        border-top: 1px solid $gray-400;
        border-radius: 6px;
        margin-top: 0px;

        @media (min-width: 1280px) {
            width: 500px;
        }
    }

    &.vs--open .vs__dropdown-toggle {
        border-bottom-color: $border-color !important;
        border-bottom-left-radius: 6px !important;
        border-bottom-right-radius: 6px !important;
    }

    .goto-container {
        height: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
        &__name {
            justify-content: left;
            display: flex;
            flex-direction: column;
            font-size: 13px;
            width: 85%;
            overflow: hidden;
            margin-right: 5%;
        }

        &__buttons {
            display: flex;
            justify-content: left;
            width: 10%;

            i {
                display: flex;
                width: 50%;
            }

            i:first-of-type {
                margin-right: 15px;
            }
        }

        @media (max-width: 580px) {
            &__name {
                width: 75%;
                margin-right: 2%;
            }

            &__buttons {
                display: flex;
                justify-content: left;
                width: 5%;

                i {
                    display: flex;
                    width: 50%;
                }

                i:first-of-type {
                    margin-right: 5px;
                }
            }
        }
    }
}
