/** 
* Custom mixins to be used for various purposes (and to keep things DRY)
*
*/

// Gradient MG-style -> used in different places as gradient bg color
@mixin mg-bg-gradient-variant($parent, $color, $ignore-warning: false) {
    #{$parent} {
        background: linear-gradient(to left, $color , #3a7374 ) !important;
    }
}



// Generate MG-style buttons (with green $primary as font color)
@mixin button-variant-mg($font-color, $background, $border, $hover-background: darken($background, 0%), $hover-border: darken($border, 0%), $active-background: darken($background, 10%), $active-border: darken($border, 0%)) {
    color: $font-color;
    @include gradient-bg($background);
    border-color: $border;
    @include box-shadow($btn-box-shadow);

    @include hover {
        color: color-yiq($hover-background);
        @include gradient-bg($hover-background);
        border-color: $hover-border;
    }

    &:focus,
    &.focus {
        // Avoid using mixin so we can pass custom focus shadow properly
        @if $enable-shadows {
            box-shadow: $btn-box-shadow, 0 0 0 $btn-focus-width rgba($border, .5);
        }
        @else {
            box-shadow: 0 0 0 $btn-focus-width rgba($border, .5);
        }
    } // Disabled comes first so active can properly restyle
    &.disabled,
    &:disabled {
        color: $font-color;
        background-color: $background;
        border-color: $border;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show>&.dropdown-toggle {
        color: $font-color;
        background-color: $active-background;
        @if $enable-gradients {
            background-image: none; // Remove the gradient for the pressed/active state
        }
        border-color: $active-border;

        &:focus {
            // Avoid using mixin so we can pass custom focus shadow properly
            @if $enable-shadows {
                box-shadow: $btn-active-box-shadow, 0 0 0 $btn-focus-width rgba($border, .5);
            }
            @else {
                box-shadow: 0 0 0 $btn-focus-width rgba($border, .5);
            }
        }
    }
}
