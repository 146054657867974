
            @import "@/assets/sass/vars.portal.scss";
          

























.v-entity-errors {
    &__field {
        font-style: italic;
    }
}
