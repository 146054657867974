
            @import "@/assets/sass/vars.portal.scss";
          

























































.text-404 {
    font-size: 90px;
    text-shadow: 5px 5px gray;
}

.bottom-image {
    position: absolute;
    width: 100%;
    bottom: 0;    
    height: 300px;
    
    background: $default;
    margin: 0;
    div {
        margin-top: 40px; 
    }
}
