
            @import "@/assets/sass/vars.portal.scss";
          































.v-errors {
    &__list {
        margin-top: 10px;
        margin-bottom: 0px;            
    }
}
