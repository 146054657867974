
            @import "@/assets/sass/vars.portal.scss";
          











































.f-styled-input {
    .input-group {
        .input-group-prepend {
            .input-group-text {
                border: none;
                padding: 0px 8px 0px 0px;
                i {
                    font-size: 20px !important;
                    color: $gray-600;
                }
            }
        }
    }

    &__input {
        margin-left: 0px;
        &.form-control {
            border: none !important;            
            padding: 0px 10px 0px 10px !important;
            border-radius: 6px !important;
            border-color: $border-color !important;
            height: 32px;
        }

        &.form-control:hover {            
            border: 1px solid $border-color !important;
            border-radius: 6px !important;
        }
    }
}
