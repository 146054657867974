// Transition
.fade-enter-active,
.fade-leave-active {
    transition: opacity .4s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}



// Router
.router-enter-active {
    width: inherit;
    transition: opacity .5s ease;

}

.router-leave-active {
    width: inherit;
    transition: opacity .5s ease;
}


.router-enter,
.router-leave-to {
    opacity: 0;
}

/* New task effect */
tr.effect-new-task {
    animation: blink 2s ease;
}

@keyframes blink {
    0% {
        background-color: yellow;
    }

    100% {
        background-color: white;
    }
}