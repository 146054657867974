
            @import "@/assets/sass/vars.portal.scss";
          

























































.vss-search {
    border: none !important;
    padding: 0 !important;

    * {
        background: transparent;
        -webkit-transition: none !important;
        -moz-transition: none !important;
        -o-transition: none !important;
        transition: none !important;
    }

    &:hover,
    &:focus,
    &.focus {
        * {
            background: white;
            border-radius: 6px;
        }
    }

    ::placeholder {
        color: $gray-600;
    }

    input {
        border-right: none;
        border-left: none;
    }

    .input-group-prepend {

        .input-group-text {
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
            border-color: $border-color;

                i {
                    color: $gray-600; 
                }
        }
    }

    .input-group-append {
        .input-group-text {
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
            border-color: $border-color;
        }
    }

    .input-group-prepend.focus {
        .input-group-text {
            border: 1px solid $active;
        }
    }

    .input-group-append.focus {
        .input-group-text {
            border: 1px solid $active;
        }
    }

}
