
            @import "@/assets/sass/vars.portal.scss";
          

































































.vfm-multiselect-multi {
    &.form-control {
        height: auto !important; 
    }

    border: none !important;
    //border: 1px solid red !important;
    padding: 0px !important;

    .vs__dropdown-toggle {
        border-radius: 4px !important;
        border-color: rgb(229, 230, 231);
        min-height: 46px;
        background-color: white;        
    }

    &.vs--open {
        .vs__dropdown-toggle {
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            
        }
    }
}
