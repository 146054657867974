
            @import "@/assets/sass/vars.portal.scss";
          













.v-m-milestone-mini {
    font-size: 12px !important;
    font-weight: bold !important;
}
