/* DASHBOARD -> for each user */
.v-dashboard {

    .quick-registration-header {

        &.align-items-center {

            @media (max-width: 580px) {
                justify-content: space-between;
            }
        }
    }
}

.v-dash-close-btn {
    //border: 1px solid red; 
    display: flex; 
    flex-grow: 1;
    justify-content: right;
    padding-right: 10px;
}
// Extra components 

.v-hours-today {

    .d-flex {

        @media (max-width: 580px) {
            flex-direction: column;

            h3 {
                margin-bottom: 15px !important;
            }

            .v-hours-today__datepicker {
                margin-bottom: 20px;
            }

            .ml-3, .ml-4 {
                margin-left: 0 !important;
            }

            &.ml-auto {
                margin-left: 0 !important;

                .btn:not(:last-child) {
                    margin-right: 0;
                    margin-top: 20px;
                }

                .btn {
                    margin-bottom:20px;
                }
            }
        }
    }

    &__datepicker {
        font-weight: bold;
        border: 1px solid $border-color;
        border-radius: 6px;
        padding: 4px 15px;
        font-weight: 400;

    }
}

.v-hours-week {

    .d-flex {

        @media (max-width: 580px) {
            flex-direction: column;

            h3 {
                margin-bottom: 15px !important;
            }
            
            .v-hours-today__datepicker {
                margin-bottom: 10px;
            }

            .ml-3 {
                margin-left: 0 !important;
            }
        }
    }

    &__graph {

        @media (max-width: 580px) {
            display: none;
        }
    }

    &__datepicker {
        font-weight: bold;
        border: 1px solid $border-color;
        border-radius: 6px;
        padding: 4px 15px;
        font-weight: 400;
    }
}

.v-hours-month {
    .d-flex {

        @media (max-width: 580px) {
            flex-direction: column;

            h3 {
                margin-bottom: 15px !important;
            }
            
            .v-hours-today__datepicker {
                margin-bottom: 10px;
            }

            .ml-3 {
                margin-left: 0 !important;
            }
        }
    }
}

.v-range-picker {

    .is-today {
        .vc-day-content {
            border: none !important;
        }
    }

    .vc-day-content {

        &:hover {
            background-color: $primary !important;
            color: $white;
        }
    }
}

/* QUick register component on dash */
.v-quick-reg {
    margin-bottom: 16px;
    
    
    &__card {
        background-color: $gray;
        border: 1px solid $gray;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
    }

    &__header {
        display: block;
    }

    .card {
        margin-bottom: 0px;
        box-shadow: 0 0 0.7rem 0 rgba(75, 81, 80, 0.20);
    }

    .card-body {
        padding-top: 1px;
        padding-bottom: 1px;
    }

    // v-quick-task in 
    .in-quick-reg {
        .card {
            box-shadow: none !important;
        }
    }


    .data-label {
        font-size: 12px;
        color: $gray-600;
    }

    .data-value {
        margin-top: 2px;
        font-size: 14px;
        font-weight: 600;

        &.__time {
            color: #444444;
        }
    }

    // Container flex
    &__wrapper {
        display: flex;
        flex-direction: row;
        //border: 1px dotted black;
        margin: 10px 25px;

        @media (max-width: 580px) {
            margin: 15px 15px;
            flex-wrap: wrap;
        }
    }

    &__customer {
        flex: 20 0;
        padding-right: 10px;

        @media (max-width: 580px) {
            flex: 0 0 100%;
            margin-bottom: 10px;
        }
    }

    &__project {
        flex: 20 0;
        padding-right: 10px;

        @media (max-width: 580px) {
            flex: 0 0 100%;
            margin-bottom: 5px;
        }
    }

    &__status {
        //border: 1px dashed red;
        text-align: center;
        padding-right: 25px;
        flex: 0 0 100px;

        @media (max-width: 768px) {
            display: none;
        }
    }

    &__time {
        //border: 1px dashed red;
        text-align: center;
        padding-right: 25px;
        flex: 0 0 100px;

        @media (max-width: 768px) {
            display: none;
        }
    }

    &__menu {
        //border: 1px dashed red;
        display: flex;
        text-align: center;
        flex: 0 0 50px;
        align-items: center;
        justify-content: center;

        @media (max-width: 580px) {
            flex: 0 0 100%;

            .dropdown-menu-right {
                right: -100px;
                top: 130%;
            }
        }
    }

    &__ctrl {
        //border: 1px dashed red;
        display: flex;
        text-align: center;
        flex: 0 0 50px;
        align-items: center;
        justify-content: center;

        @media (max-width: 580px) {
            flex: 0 0 100%;
            margin-top: 20px;
        }
    }

    &__footer {        
        text-align: center;
        background: $gray;
        border: 1px solid $gray; 
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;

        &--inner {
            height: 16px;
            line-height: 1em;
            
        }
    }

    

}