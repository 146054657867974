
            @import "@/assets/sass/vars.portal.scss";
          



























































































































































































































































.v-tiptap {
    border: 1px solid $border-color;
    border-radius: 6px;

    &__menu {
        padding: 6px 10px 6px 10px;
        border-bottom: 1px solid $border-color;

        .tt-btn {
            border: 1px solid white;
            border-radius: 6px;
            margin-right: 6px;
            color: $gray-600;
            background-color: white;

            i {
                font-size: 14px;
            }

            &:last-child {
                margin-right: 0px;
            }

            &.is-active {
                background-color: $gray-300;
            }

            &:hover {
            }
        }
    }

    &__editor {
        padding: 10px;
        min-height: 140px;
        * {
            outline: none;
        }
        p {
            font-weight: 400;
            font-size: 15px;
        }
    }
}

ul[data-type="taskList"] {
    list-style: none;
    padding: 0;

    li {
        display: flex;
        align-items: center;

        > label {
            flex: 0 0 auto;
            margin-right: 0.5rem;
        }
    }

    input[type="checkbox"] {
        cursor: pointer;
    }
}
