
            @import "@/assets/sass/vars.portal.scss";
          



























































.input-group-text.vss-decimal {
    border: none !important;
    padding: 0 !important;

    input {
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}
