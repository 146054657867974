
            @import "@/assets/sass/vars.portal.scss";
          





















































































































































































































.modal {
    .modal-dialog {
        .modal-header {
            font-size: 17px;
            font-weight: bold;
            color: #095051;
            border-bottom: 1px solid #e9ecef;

            .close {
                padding-bottom: 0px;
            }
        }
    }
}

.custom-modal {
    // Header
    &-header {
        display: flex; 
        flex-direction: row;

        &--slot {
            flex-shrink: 1; 
            flex-grow: 1;   
            //border: 1px dotted red;         
        }

        &--close {
            
            margin-top: 18px;
            flex-shrink: 0;
            width: 55px;
            border-bottom: 1px solid $border-color;
            
            button {
                color: $default;
                background: none;
                border: none;
            }

            i {
                font-weight: 400;
            }
        }
    }
}
