
            @import "@/assets/sass/vars.portal.scss";
          
















































.input-group-text.vss-currency {
    border: none !important;
    padding: 0 !important;

    input {
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .vss-code {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}
