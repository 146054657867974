
            @import "@/assets/sass/vars.portal.scss";
          
















































































































.vss-mday {
    border: none !important;
    padding: 0 !important;
    display: flex;
    flex-direction: row;

    justify-content: space-between;

    .mday-day {
        display: flex;
        width: 47%;
    }

    .mday-month {
        display: flex;
        width: 47%;
    }

    .vss-mday-name {
        display: flex;
        width: 80px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}
