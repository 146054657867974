/**
* Only new components and new classes.
*
*
*/

// General helper ;)
.mark-clickable {
    cursor: pointer;
}

// Just mark the cursor
.m-click {
    cursor: pointer;
}

.m-disabled {
    cursor: not-allowed;
}

// put text-primary to simluate linka-dinka
.m-link:hover {
    color: $primary !important;
}

.m-na {
    cursor: not-allowed;
}

// Cursor + color + font size
.a-click {
    cursor: pointer;
    color: $primary;
    font-size: 21px;

    &.disabled {
        cursor: not-allowed;
        color: $text-muted;
    }
}

.nice-info {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    padding-top: 15px;
    padding-bottom: 15px;
}

// Additional tools for working with cards
.card {
    box-shadow: 0 0 1rem 0 rgba(75, 81, 80, 0.1);

    .px-mg {
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 24px;
    }

    .px-lr {
        padding-left: 24px;
        padding-right: 24px;
    }

    .paginator {
        display: flex;
        justify-content: center;
        margin-top: 24px;
        padding-left: 24px;
        padding-right: 24px;
    }

    .card-mini-header {
        border-bottom: 1px solid $gray-300;
        font-size: 14px;
        font-weight: 600;
        background: white;
        padding: 10px;
    }
}

// Component VStatsCard
.card-mg {
    height: 106px !important;

    &.active {
        .card-title {
            color: $gray-400 !important;
        }

        .icon-area {
            i {
                color: white !important;
            }
        }

        .h1 {
            color: white !important;
        }
    }

    .icon-area {
        i {
            font-size: 40px;
        }
    }

    .h1 {
        font-size: 44px;
        line-height: 54px;
    }
}

// Component NavStatsCard
.card-nav {
    &.active {
        .card-title {
            color: $gray-400 !important;
            font-size: 13px;
        }

        .icon-area {
            i {
                color: white !important;
            }
        }

        .h1 {
            color: white !important;
        }
    }

    .icon-area {
        i {
            font-size: 35px;
        }
    }

    .h1 {
        text-transform: uppercase;
        font-size: 20px;
    }

    .with-icon {
        padding-left: 5px;
        padding-right: 10px;
    }

    .with-data {
        padding-right: 5px;
    }

    @include media-breakpoint-down(lg) {
        .h1 {
            font-size: 17px;
        }
    }

    @include media-breakpoint-down(md) {
        .with-icon {
            padding-left: 0;
            padding-right: 0;
        }

        .row {
            justify-content: center;
        }
    }
}

// Navbar bottom logo (left under scrollbar)
.sidenav {
    .navbar-bottom-logo {
        overflow: hidden;
        height: 85px;
        padding-top: 25px;
        padding-bottom: 25px;

        img {
            margin-left: 23px;
        }
    }
}

@media (max-width: 580px) {
    .sidenav {
        top: 42px !important;
    }

    .g-sidenav-pinned .sidenav {
        max-width: 100% !important;
    }
}

.sidenav-header {
    .navbar-my-profile {
        .profile-data {
            display: flex;
            align-items: center;

            .profile-left {
                margin-left: 15px;
                margin-right: 6px;
                flex: 0 0 36px;
                //border: 1px dashed red;
            }

            .profile-right {
                //border: 1px dashed red;
            }
        }

        .profile-name {
            font-size: 12px;
            color: white;
        }

        .profile-subtitle {
            font-size: 10px;
            color: white;
        }

        .profile-avatar {
        }
    }
}

// topbar continer = goto + timer
.topbar-container {
    //border: 1px dashed blue;
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;

    &__goto {
        // border: 2px dotted red;
        flex-grow: 1;
        flex-shrink: 0;
        max-width: 300px;

        .vs__actions {
            .vs__open-indicator {
                transform: scale(0.8);
            }
        }
    }

    &__flavor {
        flex-grow: 1;
        flex-shrink: 0;
        //border: 1px solid red;
        text-align: center;
    }

    &__buttons {
        //border: 2px dotted blue;
        flex: 1;
        text-align: right;
    }

    @media (max-width: 580px) {
        flex-direction: column;

        &__flavor {
            width: 100%;
            margin-bottom: 20px;
        }

        &__goto {
            width: 100%;
            margin-bottom: 15px;
            margin-top: 15px;
            max-width: 100%;
        }

        .vs__dropdown-menu {
            max-width: 100%;
        }

        &__buttons {
            width: 100%;
        }

        .v-shortcuts {
            padding-left: 0;

            &__right {
                justify-content: space-between;
            }
        }
    }
}

.navbar-expand .navbar-collapse {
    @media (max-width: 580px) {
        flex-direction: column-reverse;
    }
}

// table extensions
.table-click {
    cursor: pointer;
}

.table-mg {
    &.table-hover {
        .m-click {
            &:hover {
                color: $primary !important;
            }
        }
    }

    /*
    thead th:first-of-type {
        padding-left: 1rem !important;
    }

    thead th:last-of-type {
        padding-right: 1rem !important;
    }
    */
}

/*
.card .table td, .card .table th, .card .el-table td, .card .el-table th {
    padding-left: 1rem;
    padding-right: 1rem;
}
*/
// Title for pages
.mg-title {
    display: flex;
    align-items: center;
    flex-direction: row;

    &__left {
        flex-grow: 1;

        h1,
        h2,
        h3,
        h4 {
            margin-bottom: 0px;
        }
    }

    &__right {
        flex-grow: 0;
        justify-content: right;
    }
}

// multi-stat
.multi-stat {
    &__header {
        text-align: center;
        font-size: 15px;
        font-weight: $font-weight-bold;
    }

    &__body {
        margin-top: 8px;
        text-align: center;
        font-size: 24px;
        font-weight: $font-weight-extra-bold;
    }

    &__footer {
        margin-top: 8px;
        text-align: center;
        font-size: 15px;
        color: $gray-600;
        font-weight: $font-weight-normal;
    }
}

// table titles with place for other stuff
.mg-table-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 14px;

    &__title {
        flex-grow: 0;

        h1,
        h2,
        h3,
        h4,
        h5 {
            margin-bottom: 0px;
        }
    }

    &__actions {
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        font-size: 13px;

        &__left {
            justify-content: left;
            flex-grow: 1;
            padding-left: 14px;
        }

        &__right {
            flex-grow: 0;
            justify-content: right;
        }
    }
}

// Nice grey header in the table
.table-mg {
    thead {
        th {
            background: $gray-100;
        }
    }

    thead th,
    tbody td {
        &:first-of-type {
            padding-left: 24px;
        }

        &:last-of-type {
            padding-right: 24px;
        }
    }

    .nowrap {
        white-space: nowrap !important;
    }

    td,
    th {
        white-space: normal;
    }
}

// contract component
.contract {
    // Terminated contract
    &.is-terminated {
        border: 1px dotted $gray-600;
        background-color: $gray-200;
        opacity: 0.76;
    }

    &__description {
        font-size: 13px;

        &__type {
            font-weight: 600;
            font-size: 15px;
        }
    }

    &__controls {
        margin-top: 15px;
        display: flex;
        align-items: center;

        a {
            font-size: 21px;
        }
    }
}

.contract-revision {
    border: 1px dotted $gray-600;
    background-color: $gray-100;
    padding: 15px;
    border-radius: 4px;
    margin-bottom: 25px;

    .one-liner {
        margin-top: 4px;

        .param {
        }

        .value {
            font-weight: 600;
        }
    }
}

.contract__header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @include media-breakpoint-down(md) {
        margin-bottom: 30px;

        .contract__controls {
            float: right;
        }
    }

    @include media-breakpoint-down(xs) {
        .contract__controls {
            float: left;
        }
    }
}

@include media-breakpoint-down(lg) {
    .contract__stat {
        margin-bottom: 30px;
    }
}

@include media-breakpoint-down(xs) {
    .is-terminated {
        .contract__description {
            margin-bottom: 20px;
        }

        .text-right {
            margin-top: 20px;
        }
    }
}

// Component VBudget
.mg-budget {
    margin-bottom: 24px;
}

// Component VAssets
.mg-assets {
    margin-bottom: 24px;
}

// Additional turnover
//.additional-turnover {}

// VueFormulate

.form-control {
    border-color: $border-color;
}

// Adaptation of forms / validation
.form-group {
    .help-text {
        font-size: 13px;
        color: $gray-700;
        margin-top: 5px;
    }

    ul.has-errors {
        display: block;
        color: $danger;
        font-size: 13px;
        font-weight: 400;
        overflow-wrap: break-word;
        padding-left: 0px;
        list-style: none;
        margin-top: 4px;

        .error-entry {
            margin-left: 0px;
        }
    }

    .form-control {
        color: $body-color;
    }

    .input-group-text {
        border-color: $border-color;
    }
}

// Nice dashed separator
.line-separator {
    height: 1px;
    border-bottom: 1px dashed $gray-500;
}

.budget-modal {
    .actual-numbers {
        font-weight: bold;
        font-size: 17px;
    }
}

.text-bold {
    font-weight: bold !important;
}

// Some extras for tables

.table {
    .status-disabled {
        opacity: 0.5;
    }
}

// Button styling
.btn {
    border-radius: 6px;
}

// dropdown transition

.el-dropdown:hover .el-dropdown-menu {
    transform: translateY(0%);
    transition-delay: 0s, 0s, 0.2s;
}

// popover styling

.el-popover--plain {
    padding: 10px 20px;
}

// treeselect in tasks/projects
.v-tag__branch.internal-customer,
.v-customer-project__branch.internal-customer {
    color: $primary;
}

// Sweet alert styling

.swal2-popup .swal2-confirm {
    background-color: $primary !important;
}

// vcalendar
.vcal-primary {
    border-color: $primary !important;
}

// quote from tiptap
blockquote {
    border-top: 1px solid $gray-400;
    border-bottom: 1px solid $gray-400;
    margin: 1.5em 0px;
    padding: 0.5em 20px;
    quotes: "\201C""\201D""\2018""\2019";
    font-style: italic;
    color: $gray-600;
}

blockquote:before {
    color: #ccc;
    content: open-quote;
    font-size: 2em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.2em;
    margin-left: -15px;
}

blockquote:after {
    color: #ccc;
    content: close-quote;
    font-size: 2em;
    line-height: 0.1em;
    margin-left: 0.2em;
    vertical-align: -0.7em;
}

blockquote p {
    display: inline;
}

// V-SHORTCUTS

.v-shortcuts {
    padding-left: 15px;

    &__content {
        //border: 1px solid red;
        display: flex;
        flex-direction: row;
    }

    &__left {
        //border: 1px solid green;
        width: 80px;
        flex: 0 0 80px;
        display: flex;
    }

    &__right {
        //border: 1px solid blue;
        display: flex;
        flex: 1 1;
        justify-content: flex-end;
    }

    .btn-sm {
        padding: 0.4rem 0.5rem;
    }

    .add-shortcut {
        border: 1px dashed $primary;
        opacity: 0.6;

        &:hover {
            opacity: 1;
            border: 1px solid $primary;
            background: white;

            i {
                color: $primary;
            }
        }
    }
}

// date range picker
.range-picker {
    width: 100%;

    .daterangepicker {
        box-shadow: 0 0 1rem 0 rgba(124, 133, 132, 0.25);
    }

    .reportrange-text {
        color: $body-color !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        padding: 10px 15px !important;
    }

    .daterangepicker.show-calendar .ranges {
        width: 140px;
        padding: 0;
    }
}

// alert warning

.alert-warning {
    color: $warning;
    border-color: $warning;
    background-color: #fff5e9;
}

.text-semibold {
    font-weight: 400 !important;
}

// in-formulate under .vf-c
.formulate-input-element--fselect {
    .v-select {
        padding-left: 8px;
        padding-right: 0px;
        padding-top: 8px;
    }

    .vs {
        &__dropdown-toggle {
            border: none;
        }
    }
}

// Info boxes VInfoCard component
.info-boxes {
    //border: 1px dashed cyan;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 8px;

    @media (max-width: 1023px) {
        justify-content: center;
        flex-direction: column;
    }

    &--box {
        //border: 1px dashed black;
        //margin-right: 25px;
        flex: 0 0 210px;

        @media (max-width: 1023px) {
            flex: 0 0 100%;
            margin-bottom: 20px;
        }

        &:last-child {
            //margin-left: 60px;
        }

        & > .box-title {
            //background: red;
            font-size: 12px;
            text-align: center;
            color: $body-color;
            margin-bottom: 5px;
        }

        & > .box-data {
            //background: white;
            font-size: 18px;
            color: $default;
            text-align: center;
            font-weight: 600;
        }

        & > .box-action {
            //backgrond: white;
            margin-top: 6px;
            font-size: 12px;
            color: $primary;
            text-align: center;
        }
    }
}

.text-italic {
    font-style: italic !important;
}

.bazinga {
    border: 1px dashed red;

    * {
        border: 1px dotted green;
    }
}

.dropdown {
    .dropdown-separator {
        margin-top: 5px;
        padding-bottom: 5px;
        border-top: 1px solid $gray-200;
    }
}

// vue2 daterange picker
.dateRangeManualHeader {
    border-bottom: 1px solid rgb(221, 221, 221);
}

.dateRangeManualInputError {
    border: 1px solid red !important;
}

.dateRangeManualInput {
    height: 32px;
    border: 1px solid $gray-400;
    border-radius: 6px;
}

.dateRangeManualButton {
    border: 1px solid $gray-400;
    background: $primary;
    color: white;
    height: 32px;
    border-radius: 6px;
    margin-left: 12px;
    padding-left: 5px;
    padding-right: 5px;
}

$budget-colors: (
    budget1: #428bca,
    budget2: #5bc0de,
    budget3: #9467bd,
    budget4: #8c564b,
    budget5: #17becf,
    budget6: #5cb85c,
    budget7: #bcbd22,
    budget8: #f0ad4e,
    budget9: #7f7f7f,
    budget10: #e377c2,
    budget11: #ff7e0f,
    budget12: #c14f4f,
);

@mixin budget-color-classes {
    @each $name, $color in $budget-colors {
        .#{$name} {
            background-color: $color;
            border: 1px solid $color;
            border-radius: 5px;
            font-size: 14px;
            text-align: center;
            color: white;
            font-weight: bold;
            margin-top: 15px;
            margin-left: 4px;
            margin-right: 4px;
        }
    }
}

@mixin budget-only-color-classes {
    @each $name, $color in $budget-colors {
        .color-#{$name} {
            background-color: $color !important;            
            color: white !important;
            font-weight: bold !important;
        }
    }
}

@include budget-color-classes;
@include budget-only-color-classes;

.fa-muted {
    color: $gray-500 !important;
}