.custom-modal {


    &-header {
        &--slot {
            h3 {
                font-size: 17px; 
                font-weight: 700;
                color: $default;
            }
        }
    }
}