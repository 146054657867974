
            @import "@/assets/sass/vars.portal.scss";
          




































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.internal-note {
    font-size: 14px;
}

.customer-name {
    .styled-field__text {
        color: #7c8584;
    }
}
