
            @import "@/assets/sass/vars.portal.scss";
          










































































































































































































































.v-triple-time {
    //border: 1px dotted blue;
    display: flex;
    flex-direction: row;
    align-items: center;

    &.is-disabled {
        input {
            background-color: $gray-200;
        }
    }

    &-vmodel {
        // border: 1px solid red;
        // width: 50px;
        text-align: right;
        background: transparent;
        border: 1px solid $gray-300;
        font-size: 28px;

        @media (max-width: 1024px) {
            border: 1px solid $border-color;
        }
    }

    &:focus-within {
        .v-triple-time-vmodel {
            border: 1px solid $primary;
        }
    }

    &:hover {
        .v-triple-time-vmodel {
            border: 1px solid $primary;
        }
    }

    /* Modifiers */
    &__modifier {
        padding: 5px 5px 4px;
        line-height: 2rem;
        border-radius: 6px;
        border: 1px solid transparent;

        @media (max-width: 1024px) {
            border: 1px solid $border-color;
        }

        &:hover {
            background: $gray-100;
            //border: 1px solid $primary;

            i {
                color: $primary;
            }
        }

        i {
            color: $gray-500;
            font-weight: 400;
            font-size: 24px;
        }

        &--plus {
            margin-left: 5px;

            &:active {
                border: 1px solid $primary;
            }

            @media (max-width: 580px) {
                margin-left: 20px;
            }
        }

        &--minus {
            margin-right: 5px;

            &:active {
                border: 1px solid $primary;
            }

            @media (max-width: 580px) {
                margin-right: 20px;
            }
        }
    }

    input {
        outline: none;
        line-height: 1rem;
        font-weight: 400;
        color: $gray-900;
        &:focus {
            border: 1px solid $primary;
            //color: $primary;
        }
    }

    input.has-error {
        border: 1px solid $danger !important;
        border-radius: 6px !important;
        color: $danger !important;
    }

    .v-triple-time-vmodel {
        //border: 1px solid white;
        border-radius: 6px;
        background-color: white;
    }

    /* V-model details */
    &__hours {
        width: 50px;
        padding-right: 8px;
    }

    &__minutes {
        width: 50px;
        text-align: center;
    }

    &__seconds {
        text-align: center;
        margin-right: 30px;
    }
}
