/**
* SCSS overrides, i.e. when things are being redefined.
*
*/

@include mg-bg-gradient-variant(".bg-gradient-mg", $default, true);


.scrollbar-inner {
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    height: calc(100% - 70px);


    .sidenav-toggler-inner {
        .sidenav-toggler-line {
            background-color: white !important;
        }
    }


}

.navbar-bottom-logo {
    position: absolute;
    bottom: 0;

}

.navbar-vertical {
    .navbar-nav {

        &>li {
            &.nav-item {
                &>.nav-link.active {
                    background-color: rgba(255, 255, 255, 0.3) !important;

                    .nav-link-text {
                        color: white;
                    }
                }
            }

        }


        ul.nav-sm {
            li a.active {
                font-weight: bold !important;
                color: white !important;
            }
        }

    }
}

.breadcrumb {
    .breadcrumb-item {
        a {
            color: $default;

            &:hover {
                color: $gray-900;
            }
        }

        &.active {
            color: $gray-700;
        }
    }
}

.btn-secondary {
    @include button-variant-mg($primary, $white, $white, $primary, $primary);
}

// All tables base style
.table {
    thead {
        th {
            border-top: none;
            text-transform: none;
            font-size: 15px;
            font-weight: bold;
            // Removed 2021-10-10
            // not sure why this was added here (made by Michal add and remove)
            //padding-left: 0px;
        }
    }
}


.alert {
    &.alert-outline {
        background: white !important;
    }

    &.alert-outline.alert-secondary {
        color: $secondary;
    }

    &.alert-outline.alert-primary {
        color: $primary;
    }

    &.alert-outline.alert-default {
        color: $default;
    }

    &.alert-outline.alert-warning {
        color: $warning;
    }

    &.alert-outline.alert-danger {
        color: $danger;
    }

    &.alert-outline.alert-info {
        color: $info;
    }

    &.alert-outline.alert-success {
        color: $success;
    }
}

// Annoying outline -> remove on all in menu
.navbar-nav {
    * {
        outline: none !important;
    }
}

// Modal scrolling
.modal {
    overflow-y: auto;

    .sub-heading {
        border-bottom: 1px solid $gray-300;
        padding-bottom: 10px;
        margin-bottom: 20px;
        margin-top: 30px;
    }
}

.modal-xl {
    width: 1000px !important;
    max-width: none;

    @include media-breakpoint-down(md) {
        width: 95% !important;
    }
}

.modal-xxl {
    width: 95% !important;
    max-width: none;
}

.modal-full {
    width: 100% !important; 
    max-width: none;
    height: 100%;
    margin: 0;



    &>.modal-content {
        height: 100%;        
        border-radius: 0;        
        overflow-y: auto;
    }
}

@media screen and (min-width: 1300px) {
    .table-responsive {

        overflow-x: visible !important;
    }
}


/* Styling of dropdown */
.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
    background-color: $gray-100 !important;
    color: $body-color !important;
}

.dropdown-menu {
    box-shadow: 0 0 8px rgba(50, 50, 93, 0.15);
}

/* Popover */

/* Avatars */
.avatar {
    height: 32px;
    width: 32px;
}

.form-control:hover {
    box-shadow: none;
}

/* vue-filepond overrides */
.filepond--panel-root {
    background-color: $gray;
}

/* v-calendar today date + weekend col */
.vc-popover-content-wrapper {
    * {
        //border: 1px solid red !important; 
        z-index: 9999;
    }


    .is-today {
        .vc-day-content {
            border: 1px solid $gray-200;
            background-color: $gray-200;
            color: #383d48 !important;
            width: 24px;
            height: 24px;
        }
    }

    .vc-day-content {

        &:hover {
            background-color: $primary !important;
            color: $white;
        }
    }

    .vc-highlight {
        background-color: $primary !important;
    }


}

// el-dropdown transition fix (fired twice esp. in chrome)
.el-zoom-in-top-enter,
.el-zoom-in-top-enter-active {
    transition-property: transform;
}


// Formulate forms 

.formulate-form {

    // ul
    &-errors {
        margin-top: 10px;
        list-style: none;
        padding-left: 25px;
        margin-bottom: 10px;
    }

    // li
    &-error {
        font-size: 15px;
        color: $danger;
    }
}

// Override table-responsive on desktopr
.table-responsive {
    //overflow-x: auto !important;
    //overflow-y: visible !important;
    //padding-bottom: 85px;
    //margin-bottom: -85px;
}