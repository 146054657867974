
            @import "@/assets/sass/vars.portal.scss";
          
































































































.dropdown {
  cursor: pointer;
  user-select: none;
}

