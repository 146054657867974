
            @import "@/assets/sass/vars.portal.scss";
          
























































































































































































.pagination {
    &__counter {
        text-align: center;
        font-size: 13px;
    }
}
