
            @import "@/assets/sass/vars.portal.scss";
          





















































































































































































































































































































































































#menu-switcher {
    display: flex;
    justify-content: space-around;
    margin-left: 8px;
    margin-right: 8px;
    button {
        background: transparent;
        font-size: 10px;
        height: 25px;
        padding: 0px;
        margin: 0px;
    }
}
