// v-calendar -> bug one

.v-open-days {
    .card-body {
        padding: 0;
    }
}

.v-cal-full {
    //border:1px solid red;
    --day-border: 1px solid #b8c2cc;
    --day-border-highlight: 1px solid #b8c2cc;
    --day-width: 90px;
    --day-height: 90px;
    --weekday-bg: #3a7374;
    --weekday-border: 1px solid #eaeaea;
    border-radius: 6px;
    width: 100%;

    &.vc-container {
        border: 0px solid;
    }

    ::-webkit-scrollbar {
        width: 0px;
    }
      
    ::-webkit-scrollbar-track {
        display: none;
    }

    .vc-header {
        background-color: $white;
        padding: 10px 0;
        border-radius: 6px 6px 0 0;
    }
    
    .vc-weeks {
        padding: 0;
        overflow-x: auto !important;
        overflow-y: visible !important;
    }
    
    .vc-weekday {
        background-color: var(--weekday-bg);
        border-bottom: var(--weekday-border);
        border-top: var(--weekday-border);
        padding: 5px 0;
        color: $white;
    }
    
    .vc-day {
        padding: 0 5px 3px 5px;
        text-align: left;
        height: var(--day-height);
        min-width: var(--day-width);
        background-color: white;
        
        &.weekday-1, &.weekday-7 {
            background-color: $gray-100;
            }
        &:not(.on-bottom) {
            border-bottom: var(--day-border);
            &.weekday-1 {
              border-bottom: var(--day-border-highlight);
            }
        }
        &:not(.on-right) {
            border-right: var(--day-border);
        }
    }
    
    .vc-day-dots {
        margin-bottom: 5px;
    }
}