
            @import "@/assets/sass/vars.portal.scss";
          




































































































.le-clock {
    &__pm {
        line-height: 2rem;
        padding: 5px 5px 4px;
        font-size: 24px;
        border: 1px solid white;

        i {
            color: $gray-500;
            font-weight: 400;
            font-size: 24px;
        }

        &:hover {
            background: $gray-100;
            border: 1px solid $gray-100;
            border-radius: 6px;
            cursor: pointer;
            i {
                color: $primary;
            }
        }
    }

    &__minus {
        margin-right: 5px;
    }

    &__plus {
        margin-left: 5px;
    }

    &__sep {
        font-size: 28px;
    }
    &__tinput {
        border: 1px solid $gray-300;
        border-radius: 6px;
        width: 60px;
        text-align: center;
        font-size: 28px;
        font-weight: 400;
        color: rgb(49, 54, 54);
        line-height: 38px;
    }
}
