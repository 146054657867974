
            @import "@/assets/sass/vars.portal.scss";
          



























































































































































































































.modal {
    .modal-dialog {
        .modal-header {
            font-size: 17px;
            font-weight: bold;
            color: #095051;
            border-bottom: 1px solid #e9ecef;

            .close {
                padding-bottom: 0px;
            }
        }

        .modal-sticky {
            position: sticky;
            top: 0; /* Adjust this if you want the sticky area offset from the top */
            z-index: 1050; /* Ensure it stays above other content */
            background-color: white; /* Optional: Ensure proper visibility */

            &__content {
                background: white; 
                padding-left: 30px; 
                padding-right: 30px;
                border-bottom: 2px solid $gray-300;
                border-top: 1px solid $gray-300;
                padding-top: 10px;
                padding-bottom: 10px;
            }
        }
    }
}
