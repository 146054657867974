
            @import "@/assets/sass/vars.portal.scss";
          






















.bag-header {
  margin-bottom: 18px;
}
