  
@mixin fixed-height($from, $to, $incr) {
    $i: $from; 
    @while $i <= $to {
        $value: $i; 
        
        .f-h-#{$value} {
            height: #{$value}px !important;
        }        

        $i: $i + $incr; 
    }
}


@include fixed-height(50, 500, 25);


@mixin fixed-font($from, $to, $incr) {
    $i: $from; 
    @while $i <= $to {
        $value: $i; 
        
        .font-#{$value} {
            font-size: #{$value}px !important;
        }        

        $i: $i + $incr; 
    }
}

@include fixed-font(8, 42, 1);