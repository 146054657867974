.v-project {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0; // See https://github.com/twbs/bootstrap/pull/22740#issuecomment-305868106
    height: $card-height;
    word-wrap: break-word;
    background-color: $card-bg;
    background-clip: border-box;
    border: $card-border-width solid $white;
    @include border-radius(6px);
    margin-bottom: 10px;

    &:hover {
        -webkit-box-shadow: 0 0 2px 0 rgba(79, 87, 86, 0.4);
        box-shadow: 0 0 2px 0 rgba(79, 87, 86, 0.4);
    }

    &-viewer {
        .no-dragdrop {
            font-size: 14px;
            margin-bottom: 10px;
        }

        .text-warning {
            font-size: 14px;

        }
    }

    &__favorite {
        margin-bottom: 15px;

        .no-favorite {
            color: $gray-500;
        }
    }

    &__body {
        // Enable `flex-grow: 1` for decks and groups so that card blocks take up
        // as much space as possible, ensuring footers are aligned to the bottom.
        flex: 1 1 auto;
        // Workaround for the image size bug in IE
        // See: https://github.com/twbs/bootstrap/pull/28855
        min-height: 1px;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 30px;
        padding-right: 30px;
        color: $card-color;
    }

    &__group {
        cursor: pointer;
        // border: 1px dotted red;
        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 0px;
        }

        &--tasks {

            .v-project__value:hover,
            .v-project__label:hover {
                color: $primary;
            }
        }

        
    }

    &__label {
        font-size: 12px;
        color: $gray-label;
    }

    &__value {
        color: #444444;
        font-size: 14px;
    }

    &__budget-usage {
        margin-top: 2px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__budget-percent {
        flex-grow: 0;
        font-size: 13px;
        width: 42px;
    }

    &__budget-progress {
        flex-grow: 1;
        padding-left: 5px;
    }





    /** Desktop styles **/
    @media (min-width: 769px) {
        &__favorite {
            display: flex;
            flex: 0 0 30px;
            margin-left: 15px;
            margin-top: -8px;
            align-items: center;
            justify-content: flex-end;
            margin-bottom: 0;
        }

        &__body {

            padding-top: 8px;
            padding-bottom: 0px;
            padding-left: 20px;
        }

        &__group {
            // border: 1px dotted #888;            

            &--title {
                flex: 1 0;
                overflow: hidden;

                .v-project__value {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            &--customer {
                flex: 1 0;
                overflow: hidden;

                .v-project__value {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            &--project-model {
                flex: 0 0 170px;
            }

            &--project-type {
                flex: 0 0 110px;
            }

            &--status {
                flex: 0 0 110px;
            }

            &--budget {
                flex: 0 0 170px;
                text-align: right;
            }

            &--budget-usage {
                flex: 0 0 150px;
            }

            &--deadline {
                flex: 0 0 110px;
            }

            &--tasks {
                display: block;
                flex: 0 0 70px;
            }
        }
    }
}

/** 
PROJECT LISTING 
*/
.v-projects {
    &__section {
        &--title {
            font-size: 20px;
            font-weight: 600;
            color: $default;
            margin-bottom: 28px;
            margin-top: 42px;
        }
    }
}


.project-stats {        
    //width: 100%;    
    padding-left: 30px;
    display: flex; 
    flex-direction: row;
    justify-content: space-between;
    flex: 1 0 100%;    
    

    &__box {                    
        //border: 1px dashed green;
        text-align: center;
        padding-left: 15px; 
        padding-right: 15px;

        & > .box-title {
            font-size: 12px; 
            color: $body-color;             
        }

        & > .box-data {            
            font-size: 20px; 
            color: $default;
            font-weight: 600;
        }
    }

}

/** 
Project navigation
*/
.project-navigation {

    // Margin between the top two boxes and navigation bar 
    // (__title and __static-meta)
    $margin-title-static: 8px;

    // Project title
    &__title {
        //border: 1px dashed red;
        margin-bottom: $margin-title-static;

        h1 {
            margin-bottom: 2px;
        }

        @media (max-width: 767px) {
            text-align: center;
        }
    }

    &__static-meta {
        margin-bottom: $margin-title-static;
        //border: 1px dashed blue;
    }    

    &__metas {
        //border: 1px dashed red;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        @media (max-width: 1023px) {
            flex-direction: column;
        }

        @media (max-width: 767px) {
            justify-content: center;
            text-align: center;
        }

        &--meta {
            //border: 1px dashed green;            
            font-size: 12px;
            color: $body-color;
            margin-right: 15px;

            i {
                margin-right: 2px;
            }

            @media (max-width: 1023px) {
                margin-bottom: 5px;
            }
        }
    }


    // Sub-navigation pane
    &__submenu {
        padding-bottom: 10px;
        padding-left: 30px;
        padding-right: 30px;

        &--container {
            @media (max-width: 767px) {
                overflow-x: auto !important;
                overflow-y: visible !important;
                flex-wrap: nowrap;
                -ms-overflow-style: none;  /* IE and Edge */
                scrollbar-width: none;  /* Firefox */
    
                &::-webkit-scrollbar {
                    display: none;
                }
            }
        }


        &--box {
            text-align: center;
            height: 40px;
            line-height: 36px;
            display: block;
            text-align: center;
            color: $white;
            font-size: 14px;
            border: 1px solid #e9eae7;
            background-color: #0a5051;
            margin-left: -1px;

            &:first-child {
                border-top-left-radius: 6px;
                border-bottom-left-radius: 6px;
            }

            &:last-child {
                border-top-right-radius: 6px;
                border-bottom-right-radius: 6px;
            }

            &:hover,
            &.active {
                background: #3a7374;
                border: 1px solid #e9eae7;
                color: white;
            }
        }


    }

    @media (min-width: 769px) {

        &__title {

            //border: 1px dotted red;
        }

        &__submenu {
            margin-top: 15px;
            margin-bottom: 15px;

            //border: 1px dotted green;
        }
    }
}

// v-infobar component showing project details in project -> settings
.v-infobar {
    &__infobox {
        //border: 1px solid red;
        margin-top: 25px;
        margin-bottom: 25px;

        // Font-size on responsible user name
        &--responsible {
            font-size: 18px;
        }
    }


    @media (min-width: 769px) {
        &__infobox {
            margin-top: 0px;
            margin-bottom: 25px;
        }
    }
}

.v-filters__container__right {
    @media (max-width: 767px) {
        overflow-x: auto !important;
        overflow-y: visible !important;
    }

    .project-stats  {

        @media (max-width: 767px) {
            overflow-x: auto !important;
            overflow-y: visible !important;
            flex-wrap: nowrap;
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
            justify-content: flex-start;
            padding-left:0;

            &::-webkit-scrollbar {
                display: none;
            }

            &__box {
                flex:0 0 auto;
                padding: 0 25px;
            }
        }
    }
}

.list-view {
    .list-lane {
        &__container {
            //border: 1px solid red;
            margin-bottom: 15px;

            &:last-child {
                //border: 1px solid green;
                margin-bottom: 5px;
            }
        }

        &__column {
            &--separator {
                height: 10px;
                border-bottom: 1px solid $gray-500;
            }
        }

        &__tasks {
            //border: 1px dotted gold;
            min-height: 65px;

            &-empty {

                border: 1px solid $gray-400;
                min-height: 58px !important;
                height: 58px !important;
                border-radius: 6px;
                background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAGElEQVQYV2NkQAOMxAls3rz5v6+vL1g1ACdOBAW1iDECAAAAAElFTkSuQmCC) repeat;

            }
        }


    }
}


.board-view {}

/** TASKS */
.v-task {
    background: white;
    border: 1px solid white;
    border-radius: 6px;
    height: 60px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    position: relative;
    z-index: auto;

    &:hover {
        -webkit-box-shadow: 0 0 2px 0 rgba(79, 87, 86, 0.4);
        box-shadow: 0 0 2px 0 rgba(79, 87, 86, 0.4);
    }

    .ctx-menu {

        li {
            display: flex;
            flex: 1 0;
            align-items: center;
            padding: 5px 15px 5px 15px;
            font-size: 14px;
            color: #606266;

            &:hover {
                background: $primary;
                color: white;
            }
        }
    }

    &.v-task-completed {
        background: #d5dddd;
        border: 1px solid #d5dddd;

        .v-task__titles_title--static {
            color: #6E8485;
        }

        .v-task__time {
            opacity: 0.6;
        }

        .v-task__action-area--deadline .date-task-active {
            opacity: 0.6;
        }

        .date-overdue {
            color: #0d3233;
        }

        .v-tag i {
            color: #6E8485;
        }

        .v-customer-project i {
            color: #6E8485;
        }

        .v-task__user {
            opacity: 0.6;
        }

        .v-task__titles_title--static {
            &:before {
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background: linear-gradient(90deg, transparent 80%, #d5dddd 100%);
            }
        }

        .v-task__titles_info-area--meta {

            &:after {
                content: '';
                width: 30px;
                height: 100%;
                position: absolute;
                right: 0;
                top: 0;
                background: linear-gradient(90deg, transparent 40%, #d5dddd 100%);
            }
        }

    }

    // Overlay when the timer is running
    &__overlay {
        z-index: 100;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $default;
        opacity: 0.8;
        display: flex;
        border: 1px solid $default;
        border-radius: 6px;

        &--text {
            flex: 1;
            display: flex;
            align-items: center;
            color: white;
            justify-content: center;
            font-size: 18px;

            i {
                margin-right: 8px;
                font-size: 18px;
            }
        }
    }

    // Dragging area
    &__drag {
        display: flex;
        flex: 0 0 43px;
        align-items: center;
        padding-left: 8px;
        padding-right: 8px;
        color: $gray-500;
        font-size: 14px;
        //border: 1px dotted red;

        i:hover {
            color: $primary;
        }

        &.not-draggable {
            cursor: not-allowed;
        }
    }

    // Task title + info icons in the bottom
    &__titles {
        //border: 1px dotted blue;
        flex: 1 0;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;


        // Main task title
        &_title {
            display: flex;
            width: 100%;
            //border: 1px solid green;


            &--static {
                width: 100%;
                padding-left: 12px;
                height: 30px;
                padding-top: 3px;
                font-size: 16px;
                color: $body-color;
                position: relative;
                white-space: nowrap;

                &:before {
                    content: '';
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    background: linear-gradient(90deg, transparent 80%, white 100%);
                }

                &.is-hover {
                    //border: 1px solid $border-color;
                    //border-radius: 6px;
                }
            }

            input {
                height: 30px;
                font-size: 16px;
                color: $body-color !important;
                border: 1px solid $border-color !important;

            }
        }

        // Info area -> small icons below
        &_info-area {
            display: flex;
            flex-grow: 0;
            flex-shrink: 0;
            padding-left: 12px;
            width: 100%;
            position: relative;
            //border: 1px solid red;                    

            &--meta {
                //border: 1px dotted black;                
                white-space: nowrap;
                width: 100%;
                font-size: 12px;

                .m-click:hover {
                    color: $primary;
                }

                span {
                    font-size: 12px;
                    color: $gray-600;
                    margin-right: 10px;

                    i {
                        margin-right: 3px;
                    }
                }

                &:after {
                    content: '';
                    width: 30px;
                    height: 100%;
                    position: absolute;
                    right: 0;
                    top: 0;
                    background: linear-gradient(90deg, transparent 40%, white 100%);
                }

            }



        }

    }

    &__edit-ctrl {
        //border: 1px dotted red;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 30px;
        font-size: 14px;
        color: $gray-500;
        margin-left: 16px;

        &.hoverable:hover {
            color: $primary;
        }


        &__icons {
            //border: 1px dotted black;

            &--top {

                //border: 1px dotted red;
            }

            &--bottom {

                //border: 1px dotted green;
            }
        }
    }



    // Action icons 
    &__action-area {
        //border: 1px dotted blue;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        flex: 0 0 215px;

        i.no-selection {
            color: $gray-500;
        }

        &--deadline {
            width: 72px;
            margin-left: 30px;
            text-align: right;
            font-size: 15px;

            i:hover {
                color: $primary;
            }

            &.is-editing {
                margin-left: 0px;
                flex-grow: 1;
            }

            .date-task-active {
                font-size: 14px;
            }
        }

        &--project {
            margin-left: 35px;

            i:hover {
                color: $primary;
            }
        }

        &--tag {
            margin-left: 35px;

            i:hover {
                color: $primary;
            }

            .v-tag {
                margin-bottom: 0;
            }
        }
    }

    // Avatar
    &__user {
        display: flex;
        flex: 0 0 66px;
        align-items: center;
        padding-left: 25px;
        //border: 1px dotted red;

        .avatar-editable:hover {
            border: 2px solid $primary;
            cursor: pointer;
        }

        .avatar-not-editable:hover {
            cursor: not-allowed;
        }
    }

    // Time display
    &__time {
        //border: 1px dotted gold;         
        display: flex;
        flex: 0 0 78px;
        align-items: center;
        justify-content: flex-end;
        font-size: 16px;
        color: $body-color;
        margin-right: 14px;

        :hover {
            color: $primary;
        }
    }

    // Play + checkmark button 
    &__buttons {
        display: flex;
        flex: 0 0 91px;
        flex-direction: row;
        align-items: center;

        &--play {
            //border: 1px dotted red;            
            padding-right: 15px;

            i {
                color: $default;
                font-size: 26px;
            }

            .no-hover {
                color: $gray-500 !important;
            }
        }

        &--checkmark {
            //border: 1px dotted green;
            padding-right: 20px;

            i {
                color: $primary;
                font-size: 26px;
            }

            .no-hover {
                color: $gray-500 !important;

                &.fas {
                    color: $primary !important;
                    opacity: 0.6;
                }
            }
        }
    }

}

.create-task {
    border: 1px solid transparent;
    cursor: pointer;

    &:hover {
        color: $body-color;
    }

    &--placeholder {
        padding: 5px 10px 5px 54px;
        color: $gray-600;
        height: 46px;
        line-height: 34px;
        //font-style: italic;
        margin-bottom: 5px;
        font-size: 14px;
    }

    &--input {
        margin-bottom: 5px;

        input {
            &:focus {
                border: none;
            }

            padding-left: 54px;
        }
    }

}





/** 
Project form modal
*/

.travel-billable {
    .form-control-label {
        margin-bottom: 1.2rem;
    }
}

.project-invoicing-description {
    font-size: 14px;
}