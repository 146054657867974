
            @import "@/assets/sass/vars.portal.scss";
          

























.breadcrumb {
    li.breadcrumb-item {
        a,
        span {
            font-weight: normal !important;
        }
    }
}
