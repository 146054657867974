
            @import "@/assets/sass/vars.portal.scss";
          
























































































.queue-modal {
    &__container {
        h3 {
            margin-bottom: 30px;
        }

        &_name {
            text-align: center;
            margin-top: 30px;
            padding-bottom: 15px;
        }
    }
}
