$__show_borders: 0;

@mixin fine-scroll {
    /* Scrollbar styling */
    scrollbar-width: thin;
    scrollbar-color: $gray-500 transparent;

    ::-webkit-scrollbar {
        width: 12px;
    }

    ::-webkit-scrollbar-track {
        background: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 20px;
        border: 1px solid $gray-500;
    }
}

.v-task-form-special {
    margin-bottom: 12px;
}


.v-task-form {

    &__internal-note {
        color: $gray-700 !important;
        font-size: 14px;
    }

    // Container for header elements
    &__header_container {
        padding-left: 26px;
        padding-right: 26px;
        display: flex;
        height: 70px;
        align-items: flex-start;
        flex: 1;
        justify-content: space-between;
        vertical-align: middle;
        border-bottom: 1px solid $border-color;

        @if $__show_borders>0 {
            border: 5px dotted green;
        }
    }

    // Header left side
    &__header_elem-left {
        width: 61%;
        height: 100%;
        display: flex;
        align-items: center;
        font-size: 17px; 
        font-weight: 700;
        color: $default;

        @if $__show_borders>0 {
            border: 1px dotted cyan;
        }
    }


    &__username {
        color: $body-color;
        font-size: 15px;
        margin-left: 10px;
    }

    &__avatar {
        position: relative;

        .avatar-editable:hover {
            border: 2px solid $primary;
            cursor: pointer;
        }

        .avatar-not-editable:hover {
            cursor: not-allowed;
        }
    }

    // Header right side
    &__header_elem-right {
        width: 39%;
        display: flex;
        height: 100%;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;


        @if $__show_borders>0 {
            border: 1px dotted cyan;
        }
    }

    // Mark complete button 
    &__mark-complete {
        color: $text-muted;
        border-color: $text-muted;
        margin-top: 16px;
    }

    // Icon buttons -> right side
    &__icon_btn {
        cursor: pointer;
        color: $text-gray;
        margin-right: 30px;

        &:last-child {
            margin-right: 0px;
        }
    }

    // Main container 
    &__container {
        display: flex;
        flex-direction: row;
        height: 520px;

        @media (max-width: 1023px) {
            flex-direction: column;
            height: auto;
        }

        @if $__show_borders>0 {
            border: 1px dotted red;
        }
    }

    &__left {
        position: relative;
        flex-grow: 1;
        width: 61%;
        overflow-y: scroll;

        @media (max-width: 1023px) {
            width: 100%;
        }

        &-content {
            padding: 30px 26px 20px 26px;

            @if $__show_borders>0 {
                border: 1px dashed red;
            }


            //Tiptap editor
            .v-tiptap {

                &__editor {
                    ul[data-type="taskList"] li>label {
                        margin-bottom: 0;
                    }
                }
            }
        }

        // scroll
        @include fine-scroll();

        @if $__show_borders>0 {
            border: 1px dotted green;
        }
    }

    &__right {
        width: 39%;
        flex-grow: 1;
        background-color: $gray;
        position: relative;
        z-index: 50;

        @media (max-width: 1023px) {
            width: 100%;
            padding-top: 10px;
        }

        // Overlay for the right side that reacts 
        // on reg customer, project & activity
        &--overlay {
            z-index: 100;
            background: $white;
            width: 100%;
            height: 100%;            
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0.4;
        }

        &-content {
            padding: 15px 26px 20px 26px;
            

            @if $__show_borders>0 {
                border: 1px dashed red;
            }
        }

        @if $__show_borders>0 {
            border: 1px dotted blueviolet;
        }
    }

    &__treeselect {
        .vue-treeselect__control {
            border: none;

            .vue-treeselect__control-arrow-container {
                display: none;
            }
        }

        &:hover {
            .vue-treeselect__control {
                border: 1px solid $border-color;
                border-radius: 6px;

                .vue-treeselect__control-arrow-container {
                    display: table-cell;
                }
            }
        }

        &.vue-treeselect--open,
        &.vue-treeselect--open:hover {
            .vue-treeselect__control {
                border-top: 1px solid $border-color;
                border-left: 1px solid $border-color;
                border-right: 1px solid $border-color;
                border-bottom: none;
                border-top-left-radius: 6px;
                border-top-right-radius: 6px;
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;

                .vue-treeselect__control-arrow-container {
                    display: table-cell;
                }
            }
        }
    }



    // RIght side customs 

    &__big-timer-container {
       

       
    
    }

    // REMOVE    

    /** 
    Form fields 
    */
    &__F {
        &-title {
            textarea {
                &:focus {
                    border: 1px solid $border-color;
                    padding-left: 12px;
                    padding-right: 12px;
                }

                border: none;
                padding-left: 0px;
                padding-right: 0px;

                font-size: 22px;
                color: $body-color;
                line-height: 26px;
                font-weight: 600;
            }
        }
    }

}

/* VTimeIntervals component */

.v-time-reg {
    position: relative;

    .v-triple-time {
        /* Modifiers */
            &__modifier {        
    
                &:hover {
                    border: 1px solid $primary;
                    background-color: $gray;
                }
            }
    }
    
    .btn.disabled, .btn:disabled {
        opacity: 0.35;
        box-shadow: none;
        cursor: not-allowed;
    }

    .btn:hover {
        transform: none;
    }


    // Headline -> register time
    &__headline {
        margin-top: 18px;
        text-align: center;
        color: $default;
    }


    // No editing warning
    &__no-editing {
        height: 400px;
        display: flex;
        align-items: center;

        &--text {
            width: 100%;
            text-align: center;
            font-size: 16px;
        }
    }

    &__diff-display {
        //border: 1px dotted red;
        height: 25px;
        color: $gray-500;
        font-size: 15px;
        text-align: center;

        i {
            font-size: 12px;
        }
    }



    &__btns {
        //border: 1px dotted red; 
        margin-top: 15px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;

        &_left {}

        &_right {}
    }

    &__diff-display {
        //border: 1px dotted red;
        height: 25px;
        color: $gray-500;
        font-size: 15px;
        text-align: center;

        i {
            font-size: 12px;
        }
    }



    &__btns {
        //border: 1px dotted red; 
        margin-top: 15px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;

        &_left {}

        &_right {}
    }

    &__confirmation_btns {
        margin-top: 35px;
        //border: 1px dotted red;
        text-align: center;

        &>div {
            margin-bottom: 15px;
        }

        .btn-link {
            color: $gray-600;
        }
    }

    &__btn-modifier {
        width: 40px;
        padding: 5px;
        font-weight: 400;

        &.inactive {}
    }



    &__current-date {
        display: flex;
        justify-content: space-evenly;
        margin-top: 15px;

        &_inner {
            padding: 2px 8px;
            color: $primary;
            cursor: pointer;
            border: 1px solid transparent;

            &:hover {
                background: white;
                border-radius: 6px;
            }
        }
    }

    &__current-usage {
        margin-top: 50px;

        font-size: 24px;
        color: $body-color;
        text-align: center;        

        .form-group {
            margin-bottom: 0px;            
        }

        //border: 1px solid red;

        .v-triple-time {            
            justify-content: center;
        }

        .v-triple-time__modifier {
            font-size: 16px;
        }
    }

    &__billable {
        //border: 1px dashed red;                        
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        margin-top: 70px;

        &--label {
            margin-right: 25px;
        }

        .form-group {
            margin-bottom: 0px;
        }

    }

    &__delete-box {
        padding-bottom: 6px;
    }

    &__master-buttons {
        //border: 1px dotted green;
        text-align: center;
        position: absolute;
        width: 100%;
        bottom: -150px;

        @media (max-width: 1023px) {
            bottom: 0;
            position: relative;
            margin-top: 40px;
            margin-bottom: 30px;
        }

        // warnings under save button
        &--warn-locked {
            margin-top: 15px;
            font-size: 14px;
        }

        &--warn-invoiced {
            margin-top: 15px;
            font-size: 14px;
        }
    }

}


// The extra field that simulates FStyledSelect and FStyledInput
.styled-field {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;

    &__icon {
        font-size: 16px !important;
        color: $gray-600;
        width: 30px;
        flex: 0 0 30px;
    }

    // Select parent
    &__select {
        margin-left: 10px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        flex: 1 0;

    }

    // in-formulate 
    .vf-c {
        margin-bottom: 0px;
        font-size: 14px;
    }

    // in-formulate under .vf-c
    .form-control {
        //border: 1px solid green;
        border: none;
        height: 32px;
        padding: 0;
    }

    .vs__dropdown-toggle {
        border: none;

        &:hover {
            border: 1px solid $border-color;
        }

        .vs__actions {
            display: none;
        }
    }

    .vs--open {
        .vs__actions {
            display: block;
        }

        .vs__dropdown-toggle {
            border: 1px solid $border-color;
        }

    }


    &__text {
        margin-left: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
    }

    &__tselect {
        margin-left: 10px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
    }
}