
            @import "@/assets/sass/vars.portal.scss";
          


































































.v-sorter {
    white-space: pre;        
}
