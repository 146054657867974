
            @import "@/assets/sass/vars.portal.scss";
          






















































































































.f-styled-select {
    .input-group {
        .input-group-prepend {
            .input-group-text {
                border: none;
                padding: 0px 8px 0px 0px;
                i {
                    font-size: 20px !important;
                    color: $gray-600;

                }
            }
        }
    }

    &__v_select {
        &.form-control {
            border: none !important;
            padding: 0px !important;
            height: auto;            
        }

        
        .vs__actions {
            cursor: pointer;
            display: none;
        }


        .vs__dropdown-toggle {            
            border-radius: 6px !important;
            border-color: white !important;                                    
        }

        &.vs--open {
            .vs__dropdown-toggle {
                border-bottom: none;    
                border-bottom-left-radius: 0px !important;
                border-bottom-right-radius: 0px !important;
            }

        }

        .vs__dropdown-menu {
            border-color: $border-color !important;
            margin-top: -2px; 
            padding-top: 0px;
            box-shadow: none;
        }

        &:hover {
            .vs__dropdown-toggle {
                border-color: $border-color !important;
            }

            .vs__actions {
                display: block;
            }
        }
    }
}

.vss-multiselect {
    border: none !important;
    //border: 1px solid red !important;
    padding: 0px !important;

    .vs__dropdown-toggle {
        border-radius: 4px !important;
        border-color: rgb(229, 230, 231);
    }
}
